import React from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import RegisterForm from '../components/ProfileAuthentication/RegisterForm'
import { navigate } from 'gatsby'

if (typeof window !== 'undefined' && localStorage.getItem('user')) navigate('/')

const Signup = () => {
    return (
        <Layout title='Registrarse'>
            <PageBanner
                pageTitle='Registrarse'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Registrarse'
            />
            <RegisterForm />
        </Layout>
    )
}

export default Signup