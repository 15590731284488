import React from 'react'
import { Snackbar } from '@material-ui/core'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { Link } from 'gatsby'
import api from '../../api/credentials'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const RegisterForm = () => {
    const [error, setError] = useState(null)
    const [validation, setValidation] = useState({
        email: null,
        password: null,
        password_confirmation: null,
        phone: null,
        name: null,
        surname: null,
        idcard: null,
        birthday: null,

    })
    const [user, setUser] = useState({
        name: '',
        surname: null,
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        idcard: '',
        birthday: '',

    })

    const onInputChange = e => {
        setError(null)
        setUser({ ...user, [e.target.name]: e.target.value.trim() })
        setValidation({ ...validation, [e.target.name]: null })
    }

    const handleClose = () => {
        setError(null)
    }

    async function signup() {

        setError(null)
        api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
            api.post(`${process.env.API_URL}/signup`, user, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 201) {
                    localStorage.setItem('user', JSON.stringify(response.data))
                    window.location.href = '/'
                } else {
                    setError('Hubo un error al registrarse, contacte con la administración')
                }
            }).catch(error => {
                setValidation({
                    email: error.response.data.errors.email ? error.response.data.errors.email[0] : null,
                    password: error.response.data.errors.password ? error.response.data.errors.password[0] : null,
                    password_confirmation: error.response.data.errors.password ? error.response.data.errors.password[0] : null,
                    phone: error.response.data.errors.phone ? error.response.data.errors.phone[0] : null,
                    name: error.response.data.errors.name ? error.response.data.errors.name[0] : null,
                    surname: error.response.data.errors.surname ? error.response.data.errors.surname[0] : null,
                    idcard: error.response.data.errors.idcard ? error.response.data.errors.idcard[0] : null,
                    birthday: error.response.data.errors.birthday ? error.response.data.errors.birthday[0] : null,

                })
                setError('Revisa el formulario')
            })
        })

    }

    return (
        <div className='signup-area ptb-100'>
            <div className='container'>
                <div className='register-form'>
                    <form>
                        <div className='form-group'>
                            <label htmlFor='name'>Nombre</label>
                            <input type='text' name='name' className='form-control' placeholder='Nombre' onChange={e => onInputChange(e)} />
                            {validation.name && <span className='text-danger'>{validation.name}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='name'>Apellidos</label>
                            <input type='text' name='surname' className='form-control' placeholder='Apellidos' onChange={e => onInputChange(e)} />
                            {validation.surname && <span className='text-danger'>{validation.surname}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='phone'>Número de identificación (DNI)</label>
                            <input type='text' name='idcard' className='form-control' placeholder='Número de identificación' onChange={e => onInputChange(e)} />
                            {validation.idcard && <span className='text-danger'>{validation.idcard}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='birthday'>Fecha de Nacimiento</label>
                            <input type='date' name='birthday' className='form-control' onChange={e => onInputChange(e)} />
                            {validation.birthday && <span className='text-danger'>{validation.birthday}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='email'>Correo Electrónico</label>
                            <input type='email' name='email' className='form-control' placeholder='Correo Electrónico' onChange={e => onInputChange(e)} />
                            {validation.email && <span className='text-danger'>{validation.email}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='phone'>Teléfono</label>
                            <input type='phone' name='phone' className='form-control' placeholder='Teléfono' onChange={e => onInputChange(e)} />
                            {validation.phone && <span className='text-danger'>{validation.phone}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='password'>Contraseña</label>
                            <input type='password' name='password' className='form-control' placeholder='Contraseña' onChange={e => onInputChange(e)} />
                            {validation.password && <span className='text-danger'>{validation.password}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='password'>Confirmar contraseña</label>
                            <input type='password' name='password_confirmation' className='form-control' placeholder='Confirmar contraseña' onChange={e => onInputChange(e)} />
                            {validation.password_confirmation && <span className='text-danger'>{validation.password_confirmation}</span>}
                        </div>
                        <button type='button' onClick={signup}>Registrarse</button>
                    </form>
                    <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </div>
    )
}

export default RegisterForm